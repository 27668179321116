<template>
  <div class="row" v-if="this.$store.state.userData.role !== 'operator'">
    <div class="col-lg-3">
      <div class="card card-inverse card-warning">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-map-icon" :src="'/static/img/map.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('maps.mapsWizardTitle') }}</div>
          <div v-if="currentRouteName === 'maps-step-1'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step1') }}</p>
            <p>{{ $t('maps.mapsWizardStep1Title') }}</p>
          </div>
          <div v-if="currentRouteName === 'maps-step-2'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step2') }}</p>
            <p>{{ $t('maps.mapsWizardStep2Title') }}</p>
          </div>
          <div v-if="currentRouteName === 'maps-step-3'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step3') }}</p>
            <p>{{ $t('maps.mapsWizardStep3Title') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div :class="currentRouteName === 'maps-step-3' ? 'col-lg-9' : 'col-lg-4'"><router-view></router-view></div>
  </div>
</template>

<script>
import mapsService from '../../services/mapsService';

export default {
  name: 'MapsWizard',
  data() {
    return {
      step: new FileReader() // удалить, если не понадобится
    };
  },
  computed: {
    currentRouteName() {
      console.warn('ROUTE');
      console.warn(this.$route.name);
      return this.$route.name;
    }
  },
  methods: {
    nextStep() {
      let nextStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'maps-step-1':
          nextStep = 'maps-step-2';
          break;
        case 'maps-step-2':
          nextStep = 'maps-step-3';
          break;
        case 'maps-step-3':
          mapsService.getMaps(this);
          nextStep = 'maps';
          break;
        default:
          mapsService.getMaps(this);
          nextStep = 'maps';
      }
      this.$router.push({ name: nextStep });
    },
    prevStep() {
      let prevStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'maps-step-1':
          prevStep = 'maps';
          mapsService.getMaps(this);
          break;
        case 'maps-step-2':
          prevStep = 'maps-step-1';
          break;
        default:
          mapsService.getMaps(this);
          prevStep = 'maps';
      }
      this.$router.push({ name: prevStep });
    }
  }
};
</script>
<style lang="scss">
.wizard-map-icon {
  height: 80px;
}
</style>
