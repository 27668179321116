<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 mb-xs mb-2">{{ $t('general.step1') }}</div>
          <form action="">
            <div class="form-group"><img src="" alt="" class="map-preview img img-responsive" /></div>
            <ul class="list-unstyled mb-2" v-if="this.imageData.width && this.imageData.height">
              <li>
                {{ $t('maps.mapsWizardWidth') }}:
                <span class="text-muted">{{ this.imageData.width }} {{ $t('maps.mapsWizardPx') }}</span>
              </li>
              <li>
                {{ $t('maps.mapsWizardHeight') }}:
                <span class="text-muted">{{ this.imageData.height }} {{ $t('maps.mapsWizardPx') }}</span>
              </li>
            </ul>

            <div class="form-group mb-xs">
              <span class="fileUpload btn btn-primary">
                <i class="fa fa-file-image-o mr-h"></i>
                <span>{{ $t('general.chooseFile') }}</span>
                <input id="files" name="files" @change="changeImage" type="file" class="upload" />
              </span>
              <div><small class="text-muted">(jpg, png)</small></div>
            </div>
            <WButton primary outline
              customClass="float-right"
              @click="uploadImageToServer"
              :disabled="errors.any() || !imageData.width || !imageData.height"
            >
              {{ $t('general.next') }}
            </WButton>
            <WButton secondary outline
              customClass="float-left"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </WButton>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import mapsService from '../../services/mapsService';
import { API_URL } from '@/config';

export default {
  name: 'RadiusStep1',
  data() {
    return {
      imageData: {},
      reader: new FileReader() // удалить, если не понадобится
    };
  },
  computed: {},
  methods: {
    changeImage(event) {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        if (event.target.readyState == FileReader.DONE) {
          this.changePreviewSrc(event.target.result);

          const img = new Image();

          img.onload = () => {
            this.imageData = {
              width: img.width,
              height: img.height
            };
          };
          img.src = reader.result; // is the data URL because called with readAsDataURL
        }
      };
      // this.getWidthAndHeight(event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]);
    },
    changePreviewSrc(data) {
      const mapPreviewNode = document.querySelector('.map-preview');
      mapPreviewNode.src = data;
    },
    uploadImageToServer() {
      const images = document.querySelector('#files');
      if (!images.files.length) {
        return;
      }
      const formData = new FormData();
      formData.append(images.name, images.files[0]);
      this.axios.post(`${API_URL || ''}/api-static/maps/upload`, formData).then(
        (response) => {
          this.$store.commit('setUploadMapImageResult', response.data.data.items);
          this.nextStep();
        },
        () => {}
      );
    },
    nextStep() {
      this.$router.push({ name: 'maps-step-2' });
    }
  },
  created() {}
};
</script>

<style lang="scss">
.map-preview {
  max-width: 175px;
}
</style>
