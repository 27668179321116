<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0 mb-2">{{ $t('maps.mapsWizardFillMapParameters') }}</div>

          <form action="">
            <div class="form-group" :class="{ 'multiselect-disabled-field': isDisable }">
              <div class="form-group">
                <label for="name">{{ $t('general.name') }}</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  :placeholder="$t('maps.enterMapNamePlaceholder')"
                  class="form-control"
                  v-validate="'required|max:32'"
                  :class="{ input: true, 'is-danger': errors.has('name') }"
                  v-model="mapName"
                  :data-vv-as="$t('general.name')"
                />
                <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
              </div>

              <label for="location" v-if="areLocationsVisible">{{ $t('general.location') }}</label>
              <multiselect
                v-if="areLocationsVisible"
                data-vv-name="location"
                class=""
                v-model="selectedLocations"
                :multiple="false"
                :options="locationsItems || []"
                v-validate="'required'"
                label="name"
                id="location"
                track-by="id"
                :class="{ input: true, 'is-danger': errors.has('location'), 'multiselect-disabled': isDisable }"
                :placeholder="$t('maps.locationPlaceholder')"
                :disabled="isDisable"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
                :data-vv-as="$t('general.location')"
              ></multiselect>
              <span v-show="errors.has('location')" class="help is-danger mt-0 mb-1">
                {{ errors.first('location') }}
              </span>

              <!--          // далее для настроек времени работы-->
              <div class="form-group mt-3">
                <h6>
                  {{ $t('maps.workTimeCaption') }}
                  <info :content="$t('maps.workTimeHint')" />
                </h6>
                <div class="form-group">
                  <label for="start-work-time">
                    {{ $t('maps.workTimeStart') }}
                    <info :content="$t('maps.workTimeStartHint')" />
                  </label>
                  <input
                    type="text"
                    name="start-work-time"
                    id="start-work-time"
                    :placeholder="$t('maps.workTimeStartPlaceholder')"
                    class="form-control"
                    v-validate="'hoursMinutes'"
                    data-vv-validate-on="change"
                    :class="{ input: true, 'is-danger': errors.has('start-work-time') }"
                    v-model="start"
                    :data-vv-as="$t('maps.workTimeStart')"
                    @input="handleModalStartWorkTimeFieldInput"
                  />
                  <span v-show="errors.has('start-work-time')" class="help is-danger">
                    {{ errors.first('start-work-time') }}
                  </span>
                </div>
                <div class="form-group">
                  <label for="stop-work-time">
                    {{ $t('maps.workTimeStop') }}
                    <info :content="$t('maps.workTimeStopHint')" />
                  </label>
                  <input
                    type="text"
                    name="stop-work-time"
                    id="stop-work-time"
                    :placeholder="$t('maps.workTimeStopPlaceholder')"
                    class="form-control"
                    v-validate="'hoursMinutes'"
                    data-vv-validate-on="change"
                    :class="{ input: true, 'is-danger': errors.has('stop-work-time') }"
                    v-model="stop"
                    :data-vv-as="$t('maps.workTimeStop')"
                    @input="handleModalStopWorkTimeFieldInput"
                  />
                  <span v-show="errors.has('stop-work-time')" class="help is-danger">
                    {{ errors.first('stop-work-time') }}
                  </span>
                </div>
                <!--            <div class="form-group w-25">-->
                <!--              <label for="stop-work-time">{{ $t('maps.workTimeTimezone') }}</label>-->
                <!--              <input-->
                <!--                type="text"-->
                <!--                name="timezone-work-time"-->
                <!--                id="timezone-work-time"-->
                <!--                :placeholder="''"-->
                <!--                class="form-control"-->
                <!--                v-validate="'required|max:100'"-->
                <!--                :class="{ input: true, 'is-danger': errors.has('timezone-work-time') }"-->
                <!--                v-model="updatedMap.time_zone"-->
                <!--                :data-vv-as="$t('maps.workTimeTimezone')"-->
                <!--              />-->
                <!--              <span v-show="errors.has('timezone-work-time')"-->
                <!--                    class="help is-danger">{{ errors.first('timezone-work-time') }}</span>-->
                <!--            </div>-->
                <div class="form-group">
                  <label for="tz">{{ $t('maps.workTimeTimezone') }}</label>
                  <multiselect
                    id="tz"
                    v-model="workTimeTimezoneSelectedInMultiselect"
                    @select="
                      (selectedOpt) => {
                        time_zone = selectedOpt.utc[0];
                      }
                    "
                    :options="timeZonesOptions"
                    class="input"
                    :class="{ 'is-danger': errors.has('tz'), 'multiselect-disabled': isDisable }"
                    :disabled="isDisable"
                    :data-vv-as="$t('maps.workTimeTimezone')"
                    label="text"
                    track-by="text"
                    :empty="false"
                    :placeholder="$t('maps.workTimeTimezoneMultiselectPlaceholder')"
                    :selectLabel="$t('general.multiSelectPressToSelect')"
                    :deselectLabel="$t('general.multiSelectDeselectLabel')"
                    :selectedLabel="$t('general.multiSelectSelected')"
                  >
                    <template v-slot:noOptions>
                      {{ $t('general.notFound') }}
                    </template>
                    <template v-slot:noResult>
                      {{ $t('general.notFound') }}
                    </template>
                  </multiselect>

                  <!-- <span v-show="errors.has('tz')" class="help is-danger">{{ errors.first('tz') }}</span> -->
                  <!-- <small class="mt-h text-muted d-block" :id="'tz-caption'">{{ $t('portal.tzCaption') }}</small> -->
                </div>
              </div>

              <div class="mt-1">
                <WButton primary outline
                  customClass="float-right"
                  @click="confirmMapCreation"
                  :disabled="errors.any()"
                >
                  {{ $t('general.confirm') }}
                </WButton>
                <WButton secondary outline
                  customClass="float-left"
                  @click="$parent.prevStep"
                >
                  {{ $t('general.back') }}
                </WButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { Multiselect } from 'vue-multiselect';
import { API_URL } from '@/config';
import mapsService from '../../services/mapsService';
import helpers from '../../helpers';
// import timeZones from '../Portal/timeZones.json';
// import timeZones from '@/timeZones';
import { timeZonesListMixin } from '@/mixins';
import Info from '../../components/Universal/info-icon.vue';

export default {
  name: 'MapsStep2',
  mixins: [timeZonesListMixin],
  components: { Multiselect, Info },
  data() {
    return {
      selectedLocations: [],
      mapName: '',
      workTimeTimezoneSelectedInMultiselect: '',
      start: '',
      stop: '',
      time_zone: ''
    };
  },
  computed: {
    locationsItems() {
      // return Object.keys(this.$store.state.locationsItems).map((locationId) => ({
      //   id: locationId,
      //   name: this.$store.state.locationsItems[locationId].name
      // }));
      // const locations = Object.keys(this.$store.state.locationsItems).map((locationId) => ({
      //   id: locationId,
      //   name: this.$store.state.locationsItems[locationId].name
      // }));
      // return helpers.sortLocationsList(locations);
      return this.$store.getters.locationsListSorted;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    uploadData() {
      return this.$store.state.uploadMapImageResult;
    },
    isSuitesValidation() {
      return false;
    },
    timeZonesOptions() {
      // опиции для выбора timezones
      // return timeZones;
      return this.timeZonesList;
    }
  },
  methods: {
    confirmMapCreation() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            Vue.axios
              .post(`${API_URL || ''}/api/maps`, {
                action: 'C',
                items: {
                  0: {
                    image_id: this.uploadData.id,
                    image_url: this.uploadData.url,
                    width: this.uploadData.width,
                    height: this.uploadData.height,
                    base_location: this.selectedLocations.id,
                    name: this.mapName,
                    start: this.start,
                    stop: this.stop,
                    time_zone: this.time_zone
                  }
                }
              })
              .then(
                (response) => {
                  this.$store.commit('setUploadMapImageResult', false);
                  this.$store.commit('setCreatedMapData', response.data.data.itemslist);
                  this.$router.push({ name: 'maps-step-3' });
                },
                () => {}
              );
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    handleModalStartWorkTimeFieldInput() {
      // очищаем ошибку поля start-work-time, если она есть, каждый раз когда начанаем вводить start-work-time
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('start-work-time')) {
          this.errors.remove('start-work-time');
        }
      }
    },
    handleModalStopWorkTimeFieldInput() {
      // очищаем ошибку поля stop-work-time, если она есть, каждый раз когда начанаем вводить stop-work-time
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('stop-work-time')) {
          this.errors.remove('stop-work-time');
        }
      }
    }
  },
  watch: {
    mapName(newValue) {
      if (newValue.length > 32) {
        setTimeout(() => {
          this.mapName = newValue.substr(0, 31);
        }, 700);
      }
    }
  },
  created() {
    this.getTimeZonesFromAPI();
    if (!this.$store.state.uploadMapImageResult) {
      this.$router.push({ name: 'maps-step-1' });
    }
  }
};
</script>
